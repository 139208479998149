<template>
  <div class="wrapper">
    <div
        style="margin: 200px auto; background-color: #fff; width: 350px; height: 300px; padding: 20px; border-radius: 10px">
      <div style="margin: 20px 0; text-align: center; font-size: 24px"><b>登 录</b></div>
      <el-form :model="user" ref="userForm">
        <el-form-item prop="username">
          <el-input size="medium" style="margin: 10px 0" prefix-icon="el-icon-user" v-model="user.username"></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input size="medium" style="margin: 10px 0" prefix-icon="el-icon-lock" show-password
                    v-model="user.password"></el-input>
        </el-form-item>
        <el-form-item style="margin: 10px 0; text-align: right">
          <el-button type="primary" size="small" autocomplete="off" @click="login">登录</el-button>
          <el-button type="warning" size="small" autocomplete="off" @click="register">注册</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Home from "@/views/Home.vue";
import {setRoutes} from "@/router";

export default {
  name: "login",
  data() {
    return {
      user: {},
    }
  },
  methods: {
    login() {
      this.$refs['userForm'].validate((valid) => {
        if (valid) {  // 表单校验合法
          axios.post(this.$globle.allurl + "user/login", this.user).then(res => {
            console.log(res)
            if (res.data === "") {
              this.$message.error("用户名或密码错误")
            } else {
              localStorage.setItem("user", JSON.stringify(res.data))
              localStorage.setItem("menu", JSON.stringify(res.data.menus))
              // setRoutes()
              this.$router.push({
                path:'/Home'
              })
            }
          })
        } else {
          return false;
        }
      });
    },
    register(){
      this.$router.push({
        name:"register"
      }
      )
    }
  }
}
</script>

<style>
.wrapper {
  height: 100vh;
  background-image: linear-gradient(to bottom right, #FC466B, #3F5EFB);
  overflow: hidden;
}
</style>
