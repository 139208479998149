<template>
  <div>
    <Header index="0"/>
    <div class="container">
      <div class="container-left">
        <el-tabs v-model="activeName" height:100px>
          <el-tab-pane label="游戏标签统计" name="first">
            <div style="margin: 0 auto;padding-bottom:100px;">
              <tag-count></tag-count>
            </div>
          </el-tab-pane>
          <el-tab-pane label="游戏支持语言统计" name="third">
            <div style="margin: 0 auto;padding-bottom:100px;">
              <lang-count></lang-count>
            </div>
          </el-tab-pane>
          <el-tab-pane label="游戏评价统计" name="fourth">
            <div style="margin: 0 auto;padding-bottom:100px;">
              <view-count></view-count>
            </div>
          </el-tab-pane>
          <el-tab-pane label="各年份发售游戏统计" name="fifth">
            <div style="margin: 0 auto;padding-bottom:100px;">
              <year-count></year-count>
            </div>
          </el-tab-pane>
        </el-tabs>


      </div>

      <!-- 右侧内容显示 -->
      <el-row :gutter="10">
        <el-col
            class="hidden-xs-only hidden-sm-only"
            :xs="24"
            :sm="24"
            :md="24"
            :lg="24"
            :xl="24"
        >
          <div class="container-right">
            <img
                src="https://img.eol.cn/e_images/gk/2020/zyjd300.jpg"
                alt=""
                srcset=""
            />
            <img
                src="https://img4.eol.cn/e_images/material/fydd/cxxy/spright202.jpg"
                alt=""
                srcset=""
            />
          </div>
        </el-col>
      </el-row>
    </div>

  </div>
</template>

<script>


import YearCount from "@/views/ercharts/year-count.vue";
import ViewCount from "@/views/ercharts/view-count.vue";
import LangCount from "@/views/ercharts/lang-count.vue";
import {Header} from "element-ui";
import tagCount from "@/views/ercharts/tag-count.vue";

export default {
  data() {
    return {
      activeName: 'first',
    }
  },
  components: {
    YearCount,
    ViewCount,
    LangCount,
    Header,
    tagCount
  }
}
</script>

<style scoped>
.container {
  margin: 0 auto;
  margin-top: 0.47619rem /* 70/147 */;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.container-left {
  width: 1210px;
  background-color: white;
  margin-bottom: 20px;
  font-size: 14px;
}

.container-right {
  display: flex;
  width: 300px;
  flex-direction: column;
  margin: 0 auto;
  justify-content: center;
}

.container-right > img {
  margin: 5px 0;
}

.left-content {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 10px;
}

.swiperList {
  width: 100%;
  border: 1px solid rgb(240, 240, 240);
}

.tuijian {
  margin: 10px 0 15px;
  text-align: left;
}

.zixun {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.zixun-container {
  border: 1px solid rgb(240, 240, 240);
}

.zixun-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 14px;
  width: 260px;
  margin: 10px auto;
  text-align: left;
}

.content-value {
  /* 超出文本省略 */
  text-overflow: ellipsis;
  color: #333;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
}

.content-value:hover {
  color: tomato;
  transition-duration: 0.5s;
}

.dialog-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.dialog-content {
  width: 100%;
  height: 500px;
  background-color: rgba(255, 255, 0, 0.1);
  overflow-y: scroll;
  scroll-behavior: smooth;
  overscroll-behavior: contain;
}

/* .dialog-content::-webkit-scrollbar {
  display: none;
} */

.dialog-content-left {
  color: black;
  background-color: rgb(240, 240, 240);
  text-align: center;
  max-width: 200px;
  margin: 10px 0;
  padding: 10px 10px;
  border-radius: 5px;
}
</style>
