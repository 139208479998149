<template>
  <div>
    <div style="margin: 10px">
      <el-input placeholder="adadad" suffix-icon="el-icon-search" style="width: 20%" v-model="name"></el-input>
      <el-button type="primary" style="margin-left: 5px" @click="selectinfo()">搜索</el-button>
    </div>
    <div style="margin: 10px;display: flex">
      <el-button type="primary" @click="save">新增</el-button>
      <el-button type="danger" @click="deleteba">批量删除</el-button>
    </div>
    <el-table :data="tableData"
              stripe
              border
              row-key="id"
              default-expand-all
              :tree-props="{children: 'childrenlist'}">
              @selection-change="handleSelectionChange">
      <el-table-column
          type="selection"
          width="55">
      </el-table-column>
      <el-table-column prop="id" label="id" width="120">
      </el-table-column>
      <el-table-column prop="name" label="名称" width="120">
      </el-table-column>
      <el-table-column prop="path" label="路径">
      </el-table-column>
      <el-table-column prop="pagePath" label="页面路径">
      </el-table-column>
      <el-table-column label="图标" width="70">
        <template slot-scope="scope">
          <i :class="scope.row.icon" style="font-size: 40px;"></i>
        </template>
      </el-table-column>
      <el-table-column prop="description" label="描述">
      </el-table-column>
      <el-table-column width="300">
        <div style="float: right" slot-scope="scope">
          <el-button type="primary" @click="ftoc(scope.row)" v-if="scope.row.pid===null">新增子菜单</el-button>
          <el-button type="primary" @click="update(scope.row)">修改</el-button>
          <el-button type="danger" @click="deleteinfo(scope.row)">删除</el-button>
        </div>
      </el-table-column>
    </el-table>

    <el-dialog title="角色信息" :visible.sync="dialogFormVisible" width="20%">
      <el-form ref="form" :model="form" label-width="80px">
        <el-button type="primary" @click="resetForm('form')">重置</el-button>
        <el-form-item label="名称" prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="路径" prop="path">
          <el-input v-model="form.path"></el-input>
        </el-form-item>
        <el-form-item label="页面路径" prop="pagePath">
          <el-input v-model="form.pagePath"></el-input>
        </el-form-item>
        <el-form-item label="图标" prop="icon">
          <template>
            <el-select v-model="form.icon" placeholder="请选择">
              <el-option
                  v-for="item in opention"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                <i :class="item.value">{{item.name}}</i>
              </el-option>
            </el-select>
          </template>
        </el-form-item>
        <el-form-item label="角色描述" prop="description">
          <el-input v-model="form.description"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="infochange">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "user",
  data() {
    return {
      allurl: this.$globle.allurl,
      name: "",
      tableData: [],
      currentPage: 1,
      total: 0,
      pagesize: 10,
      dialogTableVisible: false,
      dialogFormVisible: false,
      form: {
        id: '',
        name: '',
        description: '',
      },
      formLabelWidth: '120px',
      multipleSelection: [],
      opention:[]
    }
  },
  methods: {
    deleteinfo(val) {
      axios.delete(this.allurl + "menu/delete/" + val.id)
          .then(res => {
                if (res) {
                  this.selectinfo()
                }
              }
          )
    },//删除数据

    update(val) {
      axios.get(this.$globle.allurl+"menu/icon").then(res=>{
        this.opention=res.data
      })
      this.dialogFormVisible = true
      this.form = JSON.parse(JSON.stringify(val))
    },//修改数据
    ftoc(val){
      axios.get(this.$globle.allurl+"menu/icon").then(res=>{
        this.opention=res.data
      })
      this.form = {}
      this.form.pid=val.id
      this.dialogFormVisible = true
    },
    save() {
      axios.get(this.$globle.allurl+"menu/icon").then(res=>{
        this.opention=res.data
      })
      this.form = {}
      this.dialogFormVisible = true
    },//新增数据

    infochange() {
      axios.post(this.allurl + "menu/inster", this.form).then(res => {
        if (res) {
          this.selectinfo()
        }
      })
      this.dialogFormVisible = false
    },//修改新增

    selectinfo() {
      axios.get(this.allurl+"menu/findall", {
      })
          .then(res => {
            this.tableData = res.data
          })
    },//查询数据

    handleSelectionChange(val) {
      this.multipleSelection = val;
    },//多选回调

    deleteba() {
      axios.post(this.allurl + "menu/deletemu", this.multipleSelection.map(value => value.id))
          .then(res => {
            if (res) {
              this.selectinfo()
            }
          })
    },//批量删除

  },

  created() {
    this.selectinfo()
  }
}

</script>

<style scoped>
.el-menu--collapse span {
  visibility: hidden;
}
</style>
