import Vue from 'vue'
import VueRouter from 'vue-router'
import Manager from '../views/manager.vue'
import login from "@/views/login.vue";
import register from "@/views/register.vue";
import axios from "axios";
import globle from "@/globle";
import menu from "@/views/menu/menu.vue";
import user from "@/views/user/user.vue";
import Home from "@/views/Home.vue";
import Role from "@/views/role/Role.vue";
import game from "@/views/game/game.vue";
import artical from "@/views/artical/artical.vue";
import feeitem from "@/views/fee/feeitem.vue";
import feerecord from "@/views/fee/feerecord.vue";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Manager',
        component: Manager,
        children: [
            {
                path: '/user',
                name: 'user',
                component: user,
            },
            {
                path: '/Home',
                name: 'Home',
                component: Home,
            },
            {
                path: '/role',
                name: 'role',
                component: Role,
            },
            {
                path: '/menu',
                name: 'menu',
                component: menu,
            },
            {
                path: '/game',
                name: 'game',
                component: game
            },
            {
                path: '/artical',
                name:'artical',
                component: artical
            },
            {
                path: '/feeitem',
                name:'feeitem',
                component: feeitem
            },
            {
                path: '/feerecord',
                name: 'feerecord',
                component: feerecord
            }
        ]
    },
    {
        path: '/login',
        name: 'login',
        component: login,
    },
    {
        path: '/register',
        name: 'register',
        component: register,
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})
router.beforeEach((to, from, next) => {
    let user = "";
    user = JSON.parse(localStorage.getItem("user"));
    if (to.name === 'login'||to.name === 'register') next()
    else {
        if (user === null) next({name: 'login'})
        else {
            axios.get(globle.allurl + "checktoken").then(res => {
                console.log("dadasadas")
                if (res.data) {
                    next()
                } else {
                    alert("请重新登录")
                    next({name: 'login'})
                }
            })
        }
    }
})

export default router
