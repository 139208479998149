<template>
  <div>
    <div style="margin: 10px">
      <el-input placeholder="adadad" suffix-icon="el-icon-search" style="width: 20%" v-model="name"></el-input>
      <el-button type="primary" style="margin-left: 5px" @click="selectinfo()">搜索</el-button>
    </div>
    <div style="margin: 10px;display: flex">
      <el-button type="primary" @click="save">新增</el-button>
      <el-button type="danger" @click="deleteba">批量删除</el-button>
      <el-button type="primary" @click="exportexel">导出</el-button>
      <el-upload :action="allurl+'input'" :on-success="selectinfo" :show-file-list="false">
        <!--        较为傻逼-->
        <el-button type="danger">导入</el-button>
      </el-upload>

    </div>
    <el-table :data="tableData"
              stripe
              border
              @selection-change="handleSelectionChange">
      <el-table-column
          type="selection"
          width="55">
      </el-table-column>
      <el-table-column prop="username" label="姓名" width="120">
      </el-table-column>
      <el-table-column prop="role" label="角色">
      </el-table-column>
      <el-table-column prop="address" label="地址">
      </el-table-column>
      <el-table-column prop="phone" label="电话" width="140">
      </el-table-column>
      <el-table-column prop="nickname" label="昵称" width="120">
      </el-table-column>
      <el-table-column prop="email" label="邮箱">
      </el-table-column>
      <el-table-column width="200">
        <div style="float: right" slot-scope="scope">
          <el-button type="primary" @click="update(scope.row)">修改</el-button>
          <el-button type="danger" @click="deleteinfo(scope.row)">删除</el-button>
        </div>
      </el-table-column>
    </el-table>
    <div class="block">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 30, 40]"
          :page-size=pagesize
          layout="total, sizes, prev, pager, next, jumper"
          :total=total>
      </el-pagination>
    </div>

    <el-dialog title="新增信息" :visible.sync="dialogFormVisible" width="20%">
      <el-form ref="form" :model="form" label-width="80px">
        <el-button type="primary" @click="resetForm('form')">重置</el-button>
        <el-form-item label="昵称" prop="nickname">
          <el-input v-model="form.nickname"></el-input>
        </el-form-item>
        <el-form-item label="角色" prop="role">
          <el-select v-model="form.role" placeholder="请选择">
            <el-option
                v-for="item in roles"
                :label="item.name"
                :value="item.keyrole">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="姓名" prop="username">
          <el-input v-model="form.username"></el-input>
        </el-form-item>
        <el-form-item label="电话" prop="phone">
          <el-input v-model="form.phone"></el-input>
        </el-form-item>
        <el-form-item label="邮箱" prop="email">
          <el-input v-model="form.email"></el-input>
        </el-form-item>
        <el-form-item label="住址" prop="address">
          <el-input v-model="form.address"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input v-model="form.password"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="infochange">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "user",
  data() {
    return {
      allurl: this.$globle.allurl,
      name: "",
      tableData: [],
      currentPage: 1,
      total: 0,
      pagesize: 10,
      dialogTableVisible: false,
      dialogFormVisible: false,
      form: {
        id: '',
        username: '',
        nickname: '',
        email: '',
        password: '',
        phone: '',
        address: '',
        role:''
      },
      formLabelWidth: '120px',
      multipleSelection: [],
      roles:[]
    }
  },
  methods: {
    handleSizeChange(val) {
      this.pagesize = val;
      this.selectinfo()
    },//页面变化
    handleCurrentChange(val) {
      this.currentPage = val;
      this.selectinfo()
    },//当前页面
    deleteinfo(val) {
      axios.delete(this.allurl + "user/delete/" + val.id)
          .then(res => {
                if (res) {
                  this.selectinfo()
                }
              }
          )
    },//删除数据
    update(val) {
      this.dialogFormVisible = true
      this.form = JSON.parse(JSON.stringify(val))
    },//修改数据
    save() {
      this.form = {}
      this.dialogFormVisible = true
    },//新增数据
    infochange() {
      axios.post(this.allurl + "user/inster", this.form).then(res => {
        if (res) {
          this.selectinfo()
        }
      })
      this.dialogFormVisible = false
    },//修改新增
    selectinfo() {
      axios.get(this.allurl+"user/page", {
        params: {
          pagenum: this.currentPage,
          pagesize: this.pagesize,
          username: this.name
        }
      })
          .then(res => {
            this.tableData = res.data.records
            this.total = res.data.total
          })
      axios.get(this.$globle.allurl+"role/getroles").then(res=>{
        this.roles=res.data
      })
    },//查询数据
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },//多选回调
    deleteba() {
      axios.post(this.allurl + "user/deletemu", this.multipleSelection.map(value => value.id))
          .then(res => {
            if (res) {
              this.selectinfo()
            }
          })
    },//批量删除
    exportexel() {
      window.open(this.allurl + "user/export")
    },//导出
  },

  created() {
    this.selectinfo()
  }
}

</script>

<style scoped>

</style>
