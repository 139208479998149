<template>
  <div>
    <el-row>
      <el-col :span="24">
        <div style="font-size: 12px; border-bottom: 1px solid #ccc; line-height: 60px; display: flex">
          <div style="flex: 1; font-size: 20px">
            <span :class="collapseBtnClass" style="cursor: pointer" @click="collapse"></span>
          </div>
          <el-dropdown style="width: 70px; cursor: pointer">
            <span><i class="el-icon-arrow-down" style="margin-left: 5px">{{ user.username }}</i></span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>个人信息</el-dropdown-item>
              <el-dropdown-item @click.native="quit">退出</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>

import axios from "axios";
import login from "@/views/login.vue";

export default {
  name: "Header",
  data() {
    return {
      user: JSON.parse(localStorage.getItem("user"))
    }
  },
  methods: {
    handleCommand(command) {
      if(command === "quit") {
        this.quit()
      }
    },
  quit(){
    localStorage.removeItem("user")
    localStorage.removeItem("menu")
    this.$router.push({
      path:'/login'
    })
  }
  },
  created() {
  },
  props: {
    collapseBtnClass: String,
    collapse: Function,
    nickname: String
  },
}
</script>

<style scoped>

</style>
