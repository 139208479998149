<template>
  <div>
    <div style="margin: 10px">
      <el-input placeholder="adadad" suffix-icon="el-icon-search" style="width: 20%" v-model="name"></el-input>
      <el-button type="primary" style="margin-left: 5px" @click="selectinfo()">搜索</el-button>
    </div>
    <div style="margin: 10px;display: flex">
      <el-button type="primary" @click="save">新增</el-button>
      <el-button type="danger" @click="deleteba">批量删除</el-button>
    </div>
    <el-table :data="tableData"
              stripe
              border
              @selection-change="handleSelectionChange">
      <el-table-column
          type="selection"
          width="55">
      </el-table-column>
      <el-table-column prop="rechargerecord_id" label="id" width="120" :show-overflow-tooltip="true">
      </el-table-column>
      <el-table-column prop="recharge_id" label="套餐id" :show-overflow-tooltip="true">
      </el-table-column>
      <el-table-column prop="user_id" label="用户id" :show-overflow-tooltip="true">
      </el-table-column>
      <el-table-column prop="order_number" label="订单号" :show-overflow-tooltip="true">
      </el-table-column>
      <el-table-column prop="entry_time" label="购买时间" :show-overflow-tooltip="true">
      </el-table-column>
      <el-table-column width="200" fixed="right">
        <div style="float: right" slot-scope="scope">
          <el-button type="primary" @click="update(scope.row)">修改</el-button>
          <el-button type="danger" @click="deleteinfo(scope.row)">删除</el-button>
        </div>
      </el-table-column>
    </el-table>
    <div class="block">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 30, 40]"
          :page-size=pagesize
          layout="total, sizes, prev, pager, next, jumper"
          :total=total>
      </el-pagination>
    </div>

    <el-dialog title="新增信息" :visible.sync="dialogFormVisible" width="20%">
      <el-form ref="form" :model="form" label-width="80px">
        <el-button type="primary" @click="resetForm('form')">重置</el-button>
        <el-form-item prop="recharge_id" label="套餐id" :show-overflow-tooltip="true">
          <el-input v-model="form.recharge_id"></el-input>
        </el-form-item>
        <el-form-item prop="user_id" label="用户id" :show-overflow-tooltip="true">
          <el-input v-model="form.user_id"></el-input>
        </el-form-item>
        <el-form-item prop="entry_time" label="增加时间" :show-overflow-tooltip="true">
          <el-input v-model="form.entry_time"></el-input>
        </el-form-item>
        <el-form-item prop="order_number" label="订单号" :show-overflow-tooltip="true">
          <el-input v-model="form.order_number"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="infochange">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "feerecord",
  data() {
    return {
      allurl: this.$globle.allurl,
      name: "",
      tableData: [],
      currentPage: 1,
      total: 0,
      pagesize: 10,
      dialogTableVisible: false,
      dialogFormVisible: false,
      form: {},
      formLabelWidth: '120px',
      multipleSelection: [],
      roles: []
    }
  },
  methods: {
    handleSizeChange(val) {
      this.pagesize = val;
      this.selectinfo()
    },//页面变化
    handleCurrentChange(val) {
      this.currentPage = val;
      this.selectinfo()
    },//当前页面
    deleteinfo(val) {
      axios.delete(this.allurl + "fee/deletefeerecord/" + val.rechargerecord_id)
          .then(res => {
                if (res) {
                  this.selectinfo()
                }
              }
          )
    },//删除数据
    update(val) {
      this.dialogFormVisible = true
      this.form = JSON.parse(JSON.stringify(val))
    },//修改数据
    save() {
      this.form = {}
      this.dialogFormVisible = true
    },//新增数据
    infochange() {
      axios.post(this.allurl + "fee/updataorinsertfeerecord", this.form).then(res => {
        if (res) {
          this.selectinfo()
        }
      })
      this.dialogFormVisible = false
    },//修改新增
    selectinfo() {
      axios.get(this.allurl + "fee/pageafeerecord", {
        params: {
          pagenum: this.currentPage,
          pagesize: this.pagesize,
          input: this.name
        }
      })
          .then(res => {
            this.tableData = res.data.records
            this.total = res.data.total
          })
    },//查询数据
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },//多选回调
    deleteba() {
      axios.post(this.allurl + "fee/deletemufeerecord", this.multipleSelection.map(value => value.rechargerecord_id))
          .then(res => {
            if (res) {
              this.selectinfo()
            }
          })
    },//批量删除
  },

  created() {
    this.selectinfo()
  }
}

</script>

<style scoped>

</style>
