<template>
    <div>
        <div id="langmain" style="height: 500px;width: 1200px">

        </div>
        <div id="langmain1" style="height: 500px;width: 1200px">

        </div>
    </div>

</template>

<script>
import * as echarts from "echarts";
import axios from "axios";

export default {
    name: "lang-count",
    methods: {
        drawChart() {
            this.myChart = echarts.init(document.getElementById('langmain'));
            this.myChart1 = echarts.init(document.getElementById('langmain1'));
            axios.get(this.$globle.allurl + "languageCount").then(res => {
                this.myChart.setOption({
                    title:{
                        text:'游戏支持语言柱状图',
                        left:'center'
                    },
                    dataset: [
                        {
                            dimensions: ['language', 'count'],
                            source: res.data.sort(function (a, b) {
                                return b.count - a.count;
                            }),
                        },
                    ],
                    xAxis: {
                        type: 'category',
                    },
                    yAxis: {},
                    series: {type: 'bar'},
                    dataZoom: {
                        show: true, // 为true 滚动条出现
                        realtime: true,
                        type: 'slider', // 有type这个属性，滚动条在最下面，也可以不行，写y：36，这表示距离顶端36px，一般就是在图上面。
                        height: 20, // 表示滚动条的高度，也就是粗细
                        start: 0, // 表示默认展示20%～80%这一段。
                        end: 2
                    }
                });
                this.myChart1.setOption({
                    title:{
                        text:'游戏支持语饼图',
                        left:'center'
                    },
                    dataset: [
                        {
                            dimensions: ['language', 'count'],
                            source: res.data.sort(function (a, b) {
                                return b.count - a.count;
                            }).slice(0,50),
                        },
                    ],
                    series: {type: 'pie'},
                });
            })
        },
    },
    mounted() {
        this.drawChart()
    }
}
</script>

<style scoped>

</style>
