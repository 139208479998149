import router from './router'
import Vue from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue';
import './assets/globle.css'
import globle from "@/globle";
import axios from "./axios/axios-interceptors"

Vue.config.productionTip = false
Vue.prototype.$globle = globle
Vue.prototype.$axios = axios;

Vue.use(ElementUI);
new Vue({
    router,
    render: h => h(App)
}).$mount('#app')
