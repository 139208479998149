<template>
  <div>
    <div style="margin: 10px">
      <el-input placeholder="adadad" suffix-icon="el-icon-search" style="width: 20%" v-model="name"></el-input>
      <el-button type="primary" style="margin-left: 5px" @click="selectinfo()">搜索</el-button>
    </div>
    <div style="margin: 10px;display: flex">
      <el-button type="primary" @click="save">新增</el-button>
      <el-button type="danger" @click="deleteba">批量删除</el-button>
    </div>
    <el-table :data="tableData"
              v-loading="loading"
              element-loading-text="长时间未加载请刷新重试"
              stripe
              border
              @selection-change="handleSelectionChange">
      <el-table-column
          type="selection"
          width="55">
      </el-table-column>
      <el-table-column prop="link" label="游戏链接" width="120" :show-overflow-tooltip="true">
      </el-table-column>
      <el-table-column prop="gameid" label="游戏id" :show-overflow-tooltip="true">
      </el-table-column>
      <el-table-column prop="name" label="游戏名称" :show-overflow-tooltip="true">
      </el-table-column>
      <el-table-column prop="price" label="价格" width="140" :show-overflow-tooltip="true">
      </el-table-column>
      <el-table-column prop="tab" label="标签" width="120" :show-overflow-tooltip="true">
      </el-table-column>
      <el-table-column prop="description" label="描述" :show-overflow-tooltip="true">
      </el-table-column>
      <el-table-column prop="recentreviews" label="近期评价" :show-overflow-tooltip="true">
      </el-table-column>
      <el-table-column prop="releasedate" label="发售日" :show-overflow-tooltip="true">
      </el-table-column>
      <el-table-column prop="acclaim" label="评价" :show-overflow-tooltip="true">
      </el-table-column>
      <el-table-column prop="developers" label="开发商" :show-overflow-tooltip="true">
      </el-table-column>
      <el-table-column prop="comments" label="评论" :show-overflow-tooltip="true">
      </el-table-column>
      <el-table-column prop="publisher" label="发行商" :show-overflow-tooltip="true">
      </el-table-column>
      <el-table-column prop="concerning" label="关于" :show-overflow-tooltip="true">
      </el-table-column>
      <el-table-column prop="configurationRequirements" label="配置要求" :show-overflow-tooltip="true">
      </el-table-column>
      <el-table-column prop="numberofreviews" label="评价数" :show-overflow-tooltip="true">
      </el-table-column>
      <el-table-column prop="goodrating" label="好评率" :show-overflow-tooltip="true">
      </el-table-column>
      <el-table-column prop="video" label="视频地址" :show-overflow-tooltip="true">
      </el-table-column>
      <el-table-column prop="pic" label="照片地址" :show-overflow-tooltip="true">
      </el-table-column>
      <el-table-column prop="lang" label="语言" :show-overflow-tooltip="true">
      </el-table-column>
      <el-table-column prop="gameurc" label="游戏网址" :show-overflow-tooltip="true">
      </el-table-column>
      <el-table-column width="200" fixed="right">
        <div style="float: right" slot-scope="scope">
          <el-button type="primary" @click="update(scope.row)">修改</el-button>
          <el-button type="danger" @click="deleteinfo(scope.row)">删除</el-button>
        </div>
      </el-table-column>
    </el-table>
    <div class="block">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 30, 40]"
          :page-size=pagesize
          layout="total, sizes, prev, pager, next, jumper"
          :total=total>
      </el-pagination>
    </div>

    <el-dialog title="新增信息" :visible.sync="dialogFormVisible" width="55%">
      <el-form ref="form" :model="form" label-width="80px">
        <el-button type="primary" @click="resetForm('form')">重置</el-button>
        <el-form-item prop="link" label="游戏链接" width="120" :show-overflow-tooltip="true">
          <el-input v-model="form.link"></el-input>
        </el-form-item>
        <el-form-item prop="gameid" label="游戏id" :show-overflow-tooltip="true">
          <el-input v-model="form.gameid"></el-input>
        </el-form-item>
        <el-form-item prop="name" label="游戏名称" :show-overflow-tooltip="true">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item prop="price" label="价格" width="140" :show-overflow-tooltip="true">
          <el-input v-model="form.price"></el-input>
        </el-form-item>
        <el-form-item prop="tab" label="标签" width="120" :show-overflow-tooltip="true">
          <el-input v-model="form.tab"></el-input>
        </el-form-item>
        <el-form-item prop="description" label="描述" :show-overflow-tooltip="true">
          <el-input v-model="form.description"></el-input>
        </el-form-item>
        <el-form-item prop="recentreviews" label="近期评价" :show-overflow-tooltip="true">
          <el-input v-model="form.recentreviews"></el-input>
        </el-form-item>
        <el-form-item prop="releasedate" label="发售日" :show-overflow-tooltip="true">
          <el-input v-model="form.releasedate"></el-input>
        </el-form-item>
        <el-form-item prop="acclaim" label="评价" :show-overflow-tooltip="true">
          <el-input v-model="form.acclaim"></el-input>
        </el-form-item>
        <el-form-item prop="developers" label="开发商" :show-overflow-tooltip="true">
          <el-input v-model="form.developers"></el-input>
        </el-form-item>
        <el-form-item prop="comments" label="评论" :show-overflow-tooltip="true">
          <div style="border: 1px solid #ccc;">
            <Toolbar
                style="border-bottom: 1px solid #ccc"
                :editor="editor"
                :defaultConfig="toolbarConfig"
                :mode="mode"
            />
            <Editor
                style="height: 500px; overflow-y: hidden;"
                v-model="form.comments"
                :defaultConfig="editorConfig"
                :mode="mode"
                @onCreated="onCreated"
            />
          </div>
        </el-form-item>
        <el-form-item prop="publisher" label="发行商" :show-overflow-tooltip="true">
          <el-input v-model="form.publisher"></el-input>
        </el-form-item>
        <el-form-item prop="concerning" label="关于" :show-overflow-tooltip="true">
          <div style="border: 1px solid #ccc;">
            <Toolbar
                style="border-bottom: 1px solid #ccc"
                :editor="editor"
                :defaultConfig="toolbarConfig"
                :mode="mode"
            />
            <Editor
                style="height: 500px; overflow-y: hidden;"
                v-model="form.concerning"
                :defaultConfig="editorConfig"
                :mode="mode"
                @onCreated="onCreated"
            />
          </div>
        </el-form-item>
        <el-form-item prop="configurationRequirements" label="配置要求" :show-overflow-tooltip="true">
          <el-input v-model="form.configurationRequirements"></el-input>
        </el-form-item>
        <el-form-item prop="numberofreviews" label="评价数" :show-overflow-tooltip="true">
          <el-input v-model="form.numberofreviews"></el-input>
        </el-form-item>
        <el-form-item prop="goodrating" label="好评率" :show-overflow-tooltip="true">
          <el-input v-model="form.goodrating"></el-input>
        </el-form-item>
        <el-form-item prop="video" label="视频地址" :show-overflow-tooltip="true">
          <el-input v-model="form.video"></el-input>
        </el-form-item>
        <el-form-item prop="pic" label="照片地址" :show-overflow-tooltip="true">
          <el-input v-model="form.pic"></el-input>
        </el-form-item>
        <el-form-item prop="lang" label="语言" :show-overflow-tooltip="true">
          <el-input v-model="form.lang"></el-input>
        </el-form-item>
        <el-form-item prop="gameurc" label="游戏网址" :show-overflow-tooltip="true">
          <el-input v-model="form.gameurc"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="infochange">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";
import Vue from 'vue'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'

export default {
  components: { Editor, Toolbar },
  name: "game",
  data() {
    return {
      allurl: this.$globle.allurl,
      name: "",
      tableData: [],
      currentPage: 1,
      total: 0,
      pagesize: 10,
      dialogTableVisible: false,
      dialogFormVisible: false,
      form: {},
      formLabelWidth: '120px',
      multipleSelection: [],
      roles: [],
      loading: true,

      //start
      editor: null,
      html: '<p>hello</p>',
      toolbarConfig: {},
      editorConfig: {placeholder: '请输入内容...'},
      mode: 'default', // or 'simple'
      //end
    }
  },
  methods: {
    handleSizeChange(val) {
      this.pagesize = val;
      this.selectinfo()
    },//页面变化
    handleCurrentChange(val) {
      this.currentPage = val;
      this.selectinfo()
    },//当前页面
    deleteinfo(val) {
      axios.delete(this.allurl + "delete/" + val.id)
          .then(res => {
                if (res) {
                  this.selectinfo()
                }
              }
          )
    },//删除数据
    update(val) {
      this.dialogFormVisible = true
      this.form = JSON.parse(JSON.stringify(val))
    },//修改数据
    save() {
      this.form = {}
      this.dialogFormVisible = true
    },//新增数据
    infochange() {
      axios.post(this.allurl + "updataorinsert", this.form).then(res => {
        if (res) {
          this.selectinfo()
        }
      })
      this.dialogFormVisible = false
    },//修改新增
    selectinfo() {
      this.loading = true
      axios.get(this.allurl + "getpagegamehoutai", {
        params: {
          pagenum: this.currentPage,
          pagesize: this.pagesize,
          input: this.name
        }
      })
          .then(res => {
            if (res.status === 200) {
              this.loading = false
              this.tableData = res.data.records
              this.total = res.data.total
            }

          })
    },//查询数据
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },//多选回调
    deleteba() {
      axios.post(this.allurl + "deletemu", this.multipleSelection.map(value => value.id))
          .then(res => {
            if (res) {
              this.selectinfo()
            }
          })
    },//批量删除


    onCreated(editor) {
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
    },
  },
  mounted() {
    // 模拟 ajax 请求，异步渲染编辑器
    setTimeout(() => {
      this.html = '<p>模拟 Ajax 异步设置内容 HTML</p>'
    }, 1500)
  },
  beforeDestroy() {
    const editor = this.editor
    if (editor == null) return
    editor.destroy() // 组件销毁时，及时销毁编辑器
  },
  created() {
    this.selectinfo()
  }
}

</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style scoped>

</style>
