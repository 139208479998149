import axios from "axios";
import router from "../router";
// 设置请求拦截器
axios.interceptors.request.use(config => {

    let user;
    user = JSON.parse(localStorage.getItem("user"));
    if(user==null){
        config.headers.Authorization =null
    }
    else {
        config.headers.Authorization =user.token;
    }
    // 为请求头对象，添加token验证的Authorization字段

    return config;
});

// 设置响应拦截器
axios.interceptors.response.use(
    response => {
        console.log("收到相应成功"+response)
        // 如果后端返回成功响应，则直接返回响应数据
        return response;
    },
    error => {
        console.log("收到响应失败"+error)
        router.push('/login');
    }
);

export default axios;
